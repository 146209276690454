import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import caelumbrisImage from '../../assets/images/deities/mirror.jpg';
import './DeityPathPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const shadowPulse = keyframes`
  0%, 100% { 
    text-shadow: 0 0 20px rgba(147, 112, 219, 0.8), 0 0 30px rgba(75, 0, 130, 0.6);
    box-shadow: 0 0 20px rgba(147, 112, 219, 0.4);
  }
  50% { 
    text-shadow: 0 0 30px rgba(147, 112, 219, 0.6), 0 0 40px rgba(75, 0, 130, 0.4);
    box-shadow: 0 0 30px rgba(147, 112, 219, 0.2);
  }
`;

const shadowShimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const shadowFlicker = keyframes`
  0%, 100% { opacity: 0.8; }
  50% { opacity: 0.6; }
`;

const shadowReveal = keyframes`
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const shadowMist = keyframes`
  0%, 100% { 
    background-position: 0% 50%;
    filter: blur(8px);
  }
  50% { 
    background-position: 100% 50%;
    filter: blur(12px);
  }
`;

const StyledShadowPage = styled.div`
  background: linear-gradient(to bottom, #000000, #1a1a1a);
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(
        circle at 50% 50%,
        rgba(75, 0, 130, 0.1),
        transparent 70%
      );
    pointer-events: none;
    z-index: 1;
  }

  .deity-path-hero {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 200px;
      background: linear-gradient(to bottom, transparent, #000000);
      pointer-events: none;
    }
  }

  .deity-path-background {
    filter: brightness(0.7) contrast(1.2);
    transition: all 0.5s ease;

    &:hover {
      filter: brightness(0.8) contrast(1.3);
    }
  }

  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #483D8B, #9370DB, #4B0082);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    animation: ${shadowPulse} 3s ease-in-out infinite;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, 
        transparent,
        rgba(147, 112, 219, 0.2),
        transparent
      );
      background-size: 200% 100%;
      animation: ${shadowShimmer} 5s infinite linear;
      z-index: -1;
    }
  }

  .content-block, .trait {
    animation: ${shadowReveal} 0.8s ease-out forwards;
    backdrop-filter: blur(5px);
    margin: 2rem 0;
    padding: 2rem;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(135deg, 
        rgba(147, 112, 219, 0.05),
        rgba(75, 0, 130, 0.05)
      );
      animation: ${shadowMist} 10s infinite;
      pointer-events: none;
    }
  }

  h2 {
    color: #9370DB;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(147, 112, 219, 0.5);
    margin-bottom: 1rem;
    
    &:hover {
      animation: ${shadowPulse} 1.5s ease-in-out infinite;
    }
  }

  h3 {
    color: #B19CD9;
    font-size: 1.5rem;
    text-shadow: 0 0 8px rgba(147, 112, 219, 0.4);
    margin: 1rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      color: #9370DB;
      text-shadow: 0 0 12px rgba(147, 112, 219, 0.6);
    }
  }

  p {
    color: #E6E6FA;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(230, 230, 250, 0.5);
  }

  strong {
    color: #9370DB;
    text-shadow: 0 0 5px rgba(147, 112, 219, 0.5);
    font-weight: bold;
  }

  ul li {
    color: #E6E6FA;
    position: relative;
    
    &::before {
      content: '◈';
      color: #9370DB !important;
      text-shadow: 0 0 5px rgba(147, 112, 219, 0.5);
      margin-right: 10px;
    }

    &:hover::before {
      color: #B19CD9 !important;
      text-shadow: 0 0 5px rgba(147, 112, 219, 0.7);
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #9370DB, #4B0082);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 
        0 0 10px rgba(147, 112, 219, 0.5),
        0 0 20px rgba(75, 0, 130, 0.3);
    }
  }

  .requirements-section {
    border: 1px solid rgba(147, 112, 219, 0.3);
    background: rgba(0, 0, 0, 0.8);
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        repeating-linear-gradient(
          45deg,
          transparent,
          transparent 10px,
          rgba(147, 112, 219, 0.02) 10px,
          rgba(75, 0, 130, 0.02) 20px
        );
      pointer-events: none;
    }
  }

  .veiled-paths h2 {
    color: #9370DB;
    text-shadow: 0 0 10px rgba(147, 112, 219, 0.5);
  }

  .veiled-link {
    color: #E6E6FA !important;
    transition: all 0.3s ease;

    &:hover {
      color: #9370DB !important;
      text-shadow: 0 0 8px rgba(147, 112, 219, 0.4);
    }
  }

  .requirement-warning {
    position: relative;
    padding: 1rem;
    margin: 1rem 0;
    
    &::before {
      content: '⚠';
      position: absolute;
      left: -1.5rem;
      color: #9370DB;
      animation: ${shadowPulse} 2s infinite;
    }
  }

  .specializations-list {
    li {
      transition: all 0.3s ease;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      
      &:hover {
        background: rgba(147, 112, 219, 0.1);
        transform: translateX(10px);
        
        &::before {
          transform: scale(1.2);
        }
      }
    }
  }

  .dark-block {
    background: rgba(0, 0, 0, 0.9);
    border: none;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        linear-gradient(45deg,
          transparent 0%,
          rgba(147, 112, 219, 0.1) 50%,
          transparent 100%
        );
      animation: ${shadowShimmer} 8s infinite linear;
      pointer-events: none;
    }
  }

  .blue-text {
    color: #9370DB;
    text-shadow: 0 0 10px rgba(147, 112, 219, 0.5);
  }

  .image-modal {
    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.8);
    
    .modal-image {
      box-shadow: 0 0 30px rgba(147, 112, 219, 0.3);
    }
  }
`;

const CaelumbrisShadowed = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);
  
  // The sequence of pages for navigation - following dropdown menu order
  const pageSequence = [
    '/deities/caelumbris/shadowed',
    '/deities/firstborn/soulbound',
    '/deities/libris/knowledge',
    '/deities/nebula/mistwalker',
    '/deities/queenmab/frozenveil',
    '/deities/queen-titania/wildgrowth',
    '/deities/sacrathor/dominion',
    '/deities/unbound',
    '/deities/valdis/justice',
    '/deities/wildscar/emberfury'
  ];

  const pageVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  const contentVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.6 }
  };

  const traitVariants = {
    initial: { x: -20, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: { duration: 0.4 }
  };

  return (
    <StyledShadowPage 
      className="deity-path-page"
    >
      <motion.div 
        className="deity-path-page"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
      >
        <motion.div 
          className="deity-path-hero"
          variants={contentVariants}
        >
          <img 
            src={caelumbrisImage} 
            alt="Caelumbris: Shadowed Path" 
            className="deity-path-background"
            onClick={() => setShowFullImage(true)}
            style={{ cursor: 'pointer' }}
          />
          <motion.div 
            className="deity-path-title"
            variants={contentVariants}
          >
            <h1>Caelumbris: The Shadowed Path</h1>
          </motion.div>
        </motion.div>

        <motion.div 
          className="content-block requirements-section"
          variants={contentVariants}
        >
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Acquire and strategically leverage secrets to manipulate events and influence outcomes.</li>
              <li>Embrace deception and misdirection as tools for personal gain and the advancement of Caelumbris's hidden agenda.</li>
              <li>Infiltrate positions of power to control the flow of information and subtly guide others toward your desired outcomes.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li className="requirement-warning">You are a member of the Keepers and must follow their orders. Members may never speak about the Keepers to a non-member and are magically and physically prohibited from doing so, with this pact enforced by the Firstborn.</li>
              <li>Always maintain a hidden identity or alias to conceal your true allegiance to Caelumbris.</li>
              <li>Seek to acquire compromising information about individuals in positions of power to use as leverage for your schemes.</li>
            </ul>
          </div>
        </motion.div>

        <motion.div 
          className="content-block dark-block"
          variants={contentVariants}
        >
          <h2 className="blue-text">Caelumbris: The Shrouded Catalyst</h2>
          <p>
            Deep in the Shattered Realm, where light fails and darkness reigns, Caelumbris, the Shrouded Catalyst, holds dominion. Every unlit corner of existence connects to this being's omnipresent network of living shadow. More than mere darkness, it is an aware force—observing, guiding, and whispering from the edges of perception. Tales speak of a primordial pact between Caelumbris and the twilight, granting it authority over the darkest places that light cannot reach. Those who devote themselves to Caelumbris learn to harness these living shadows, using them as tools to influence the world from just outside its awareness.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Caelumbris's Domain</h3>
          </div>
          <p>
            Caelumbris's realm is a mirror-world of perfect shadow, culled from every cast silhouette and hidden place the light cannot reach. This fluid tapestry pulses with watchful energy, scrutinizing all who traverse it. Every step feels accompanied by a presence that exists just beyond sight. Navigating this domain means walking through echoes of secrets and hushes of silent motion, interwoven with the essence of Caelumbris itself.
          </p>
        </motion.div>

        <h2>Levels and Abilities</h2>
        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>LEVEL 3: SHADOW INITIATE</h3>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>Shadow Duplicates</h3>
            </div>
            <p>At 3rd level, you learn to sculpt pockets of gloom into illusory echoes of yourself:</p>
            <ul className="specializations-list">
              <li><strong>Summoning:</strong> As a bonus action, you conjure up to a number of shadow duplicates equal to your proficiency bonus. Each one appears as a blurry outline of your form.</li>
              <li><strong>Limitations:</strong> These duplicates cannot attack or handle tasks requiring precise dexterity or significant intelligence. They function like obedient, intangible servants—capable of holding objects, opening doors, and causing diversions.</li>
              <li><strong>Control & Duration:</strong> Commanding the duplicates to move (up to 30 feet each) requires a bonus action. They must remain within 30 feet of you and in your line of sight. Exposure to bright light or direct sunlight immediately dissolves any duplicate.</li>
              <li><strong>Interaction:</strong> You can speak or listen through only one duplicate at a time. Each projects a faint whispering resonance, reinforcing the illusion of an actual presence.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Clone Shift</h3>
            </div>
            <p>You can instantly exchange positions with one of your duplicates:</p>
            <ul className="specializations-list">
              <li><strong>Effect:</strong> As a reaction, if a shadow duplicate is within 30 feet and in your line of sight, you can switch places with it. Observers witness only a flicker of movement. You can use this feature a number of times equal to your proficiency bonus. You regain all expended uses when you finish a long rest.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shrouded Insight</h3>
            </div>
            <ul className="specializations-list">
              <li>Gain proficiency in Perception and Insight. If you already have proficiency in either, you gain expertise (double your proficiency bonus) for that skill.</li>
              <li>You have advantage on checks to detect hidden creatures or interpret cryptic information.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Cloak of Deception</h3>
            </div>
            <ul className="specializations-list">
              <li><strong>Illusory Resilience:</strong> When an enemy hits a duplicate with an attack, that enemy must succeed on an Intelligence (Investigation) check against your spell save DC to realize it's an illusion. On a failure, the attack seemingly passes through harmlessly. On a success, the attacker discerns the duplicate's fake nature.</li>
            </ul>
          </div>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>LEVEL 5: SHADOWED VEIL</h3>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>Shadow Familiar</h3>
            </div>
            <p>At 5th level, you surpass creating minor illusions and instead bind your own shadow into a loyal entity. This feature coexists with your basic duplicates:</p>
            <ul className="specializations-list">
              <li><strong>Permanent Attachment:</strong> Your shadow peels away from you, existing as a single familiar. As long as it persists, you have no normal shadow.</li>
              <li><strong>Familiar Traits:</strong> It behaves similarly to a find familiar, except it appears as a perfect silhouette of you. It can deliver touch spells, use the Help action, and handle basic tasks.</li>
              <li><strong>Attack Through Familiar:</strong> Once per round, when you take the Attack action, you can make a melee or ranged weapon attack through your familiar, using your own attack and damage rolls. This attack originates from the familiar's position rather than your own.</li>
              <li><strong>Senses & Telepathy:</strong> You can see and hear through the familiar's senses up to 100 feet and communicate mentally with it across any distance on the same plane.</li>
              <li><strong>Dismissal & Reforming:</strong> If the familiar is destroyed or dismissed (an action), it merges back into you. You must complete a short or long rest before summoning it again.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shadowed Perception</h3>
            </div>
            <ul className="specializations-list">
              <li><strong>Extended Darkvision:</strong> You gain darkvision to a range of 120 feet and can perceive through magical darkness as if it were dim light.</li>
              <li><strong>Heightened Senses:</strong> While in dim light or darkness, you have advantage on Wisdom (Perception) checks.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Veil of Secrets</h3>
            </div>
            <ul className="specializations-list">
              <li><strong>Mind Ward:</strong> Spells or effects that attempt to read your thoughts, pinpoint your location, or force you to speak truthfully automatically fail.</li>
              <li><strong>Light Suppression:</strong> For each character level you have, a 1-foot radius around you is reduced by one step of illumination (bright light becomes dim, dim becomes darkness), unless the light source exceeds your power (e.g., a higher-level spell).</li>
            </ul>
          </div>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>LEVEL 10: UMBRAL DOMINION</h3>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>Shadowed Sanctum</h3>
            </div>
            <ul className="specializations-list">
              <li><strong>Doorway of Gloom:</strong> As an action, form a shadowy portal on a solid surface within reach. Stepping through leads to a dim, tranquil dwelling of comforting twilight.</li>
              <li><strong>Duration & Capacity:</strong> The sanctum lasts for 24 hours or until you dismiss it. It can accommodate you plus 5 × your proficiency bonus other creatures.</li>
              <li><strong>Basic Amenities:</strong> Shadowy furniture, subdued lighting, and silent attendants of living darkness provide for basic needs.</li>
              <li><strong>Usage:</strong> You can create this sanctum a number of times equal to your proficiency bonus, regaining expended uses on a long rest.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shadow Network</h3>
            </div>
            <ul className="specializations-list">
              <li><strong>Telepathic Link:</strong> You can project your thoughts to any creature within 60 feet you can see—or that your shadow familiar has seen. Others cannot intercept these messages.</li>
            </ul>
          </div>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>LEVEL 15: CHAMPION OF SHADOWS</h3>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>Shadow Twin</h3>
            </div>
            <p>Your familiar grows into a fully independent form identical to your own:</p>
            <ul className="specializations-list">
              <li><strong>Permanent Twin:</strong> The "shadow familiar" becomes a Shadow Twin—an autonomous copy of you. You remain shadowless while it exists.</li>
              <li><strong>Separate Turn:</strong> The twin rolls its own initiative and may take a full turn (Action, Bonus Action, Movement, Reaction). However, it draws from your spell slots, limited-use abilities, and other daily resources.</li>
              <li><strong>HP & Demise:</strong> While active, your total hit points are evenly split between you and the twin. For instance, if your maximum HP is 80, each body has 40 HP. Damage to one body does not affect the other's HP. If either body is reduced to 0 HP, that body collapses into inky mist. If both drop to 0 HP simultaneously, you fall unconscious as usual.</li>
              <li>When you dismiss or reabsorb the twin (using an action), you regain any remaining hit points from it, up to your normal maximum. If the twin is destroyed, you can't recreate it until after a short or long rest.</li>
              <li><strong>One Concentration:</strong> You can maintain only a single concentration spell, even with two bodies.</li>
              <li><strong>Dispel & Reform:</strong> You can merge the twin back into yourself as an action. If it's destroyed, you must finish a rest before summoning it again.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Eclipse of Shadows</h3>
            </div>
            <p>You can release a surge of crushing darkness and disorienting whispers. As an action, choose a point you or your Twin can see within 60 feet. A 30-foot-radius sphere of oppressive gravity manifests there. Each creature in the sphere must make a Dexterity saving throw (DC = 8 + your proficiency bonus + your spellcasting ability modifier).</p>
            <ul className="specializations-list">
              <li><strong>On a Failed Save:</strong> A creature takes 8d8 necrotic damage, is knocked prone, and becomes afflicted by haunting visions for 1 minute. It perceives illusions of its darkest secrets being exposed, convinced that those nearby witness them too.</li>
              <li><strong>On a Successful Save:</strong> A creature takes half damage, remains standing, and is not subjected to the illusions.</li>
            </ul>
            <p>Affected creatures may repeat the Dexterity saving throw at the end of each of their turns, ending the effect on a success. The illusions also end if the creature experiences a sufficiently startling or harmful effect (at the GM's discretion). Once you use this feature, you cannot use it again until you finish a short or long rest.</p>
          </div>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Roleplaying the Path of Caelumbris</h3>
          </div>
          <p>
            Followers of Caelumbris embrace the shadows, becoming extensions of its omnipresent will. They operate as unseen architects of schemes, spies, and manipulators, orchestrating events from the safety of darkness. Their actions are deliberate, guided by the knowledge gleaned from the shadows they command. Whether swaying the decisions of leaders or uncovering buried secrets, these adherents wield the power of the unseen to shape the world to their design. To walk this path is to become a silent observer, a master puppeteer whose strings are never seen.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <p>
            Following the Shadowed Path grants mastery over the living shadows,
            providing unparalleled control over light and darkness. Followers of
            this path become agents of secrecy and manipulation, merging with the
            shadows to become indistinguishable from the forces they command.
            This journey requires introspection and adaptation, as practitioners
            learn to harness the boundless potential of shadow to control,
            conceal, and manipulate their surroundings.
          </p>
        </motion.div>

        {showFullImage && (
          <motion.div 
            className="image-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowFullImage(false)}
          >
            <motion.div 
              className="modal-image-container"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
            >
              <img 
                src={caelumbrisImage} 
                alt="Caelumbris: Shadowed Path"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Caelumbris.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </StyledShadowPage>
  );
};

export default CaelumbrisShadowed;
